
import { Trash, Plus } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog";
import { Input } from "../../../core/components/ui/input";
import { Textarea } from "../../../core/components/ui/textarea";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { getCategoryConfig, saveCategoryConfig } from "../../services/categoryConfig";
import { Label } from "../../../core/components/ui/label";
import { Button } from "../../../core/components/ui/button";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../core/components/ui/select";
import { Switch } from "../../../core/components/ui/switch";
import { Card, CardContent, CardHeader } from "../../../core/components/ui/card";
import ReactSelect from "react-select";
import { agents } from "../../models/agentConfig-model";



export interface DialogProps {
    isDialogOpen: boolean | undefined
    setIsDialogOpen: (value: boolean) => void
    currentCategory?: any;
    isEditing: boolean;
    agent: agents[]
}


export const CategoryDialog = ({ isDialogOpen, setIsDialogOpen, currentCategory, isEditing, agent }: DialogProps) => {

    const { integrationList } = useSelector((state: RootState) => state.userSetting);

    const { register, setValue, reset, control, watch, handleSubmit, getValues, formState: { errors, isValid } } = useForm({
        defaultValues: {
            title: '',
            categoryFolders: [{ title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }],
            prompt: '',
            isCollate: false,
            agents: [] as any
        }
    });

    const dispatch: AppDispatch = useDispatch();


    const isCollate = watch('isCollate', false);
    const folders = watch('categoryFolders', [{ title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }]);


    const handleSave = (data: any) => {

        const agentIds = data.agents;
        data.categoryFolders = data.categoryFolders.map((item: any) => {
            item.paths = [];
            return item
        })

        const updatedCategory = { ...currentCategory, ...data, agents: agentIds };

        dispatch(saveCategoryConfig(updatedCategory))
            .then(() => {
                if (isEditing && currentCategory) {
                    toast.success('Category updated successfully');
                } else {
                    toast.success("Category added successfully");
                }
                resetForm()
                setIsDialogOpen(false);
                dispatch(getCategoryConfig())
            })
            .catch((error) => {
                if (isEditing && currentCategory) {
                    toast.error('Error updating category', error);
                } else {
                    toast.error('Error Category Added', error);
                }
                resetForm()
                setIsDialogOpen(false);
            });

    };

    const resetForm = () => {
        reset({
            title: '',
            categoryFolders: [{ title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }],
            prompt: '',
            isCollate: false,
            agents: []
        })
    };

    const addFolder = () => setValue('categoryFolders', [...folders ?? [], { title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }]);


    const removeFolder = (index: number) => {
        if (folders.length > 1) {
            const updatedFolders = folders.filter((_, i) => i !== index);
            setValue('categoryFolders', updatedFolders);
        }
    };


    useEffect(() => {
        resetForm()
        if (currentCategory) {
            setTimeout(() => {
                setValue('title', currentCategory.title);
                setValue('categoryFolders', currentCategory.categoryFolders);
                setValue('prompt', currentCategory.prompt);
                setValue('isCollate', currentCategory.isCollate);
                setValue('agents', currentCategory.agents?.map((ag: agents) => ag.id) || []);
            }, 500)
        }

    }, [currentCategory])

    const agentOptions = agent?.map((ag: agents) => ({
        value: ag.id,
        label: ag.title
    })) || [];


    return (
        <>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent className="w-full sm:max-w-[1300px] p-4 sm:p-6 rounded-md bg-white max-h-[80vh] overflow-y-auto">
                    <DialogHeader>
                        <DialogTitle>{isEditing ? 'Edit Category' : 'Add New Category'}</DialogTitle>
                        <DialogDescription></DialogDescription>
                    </DialogHeader>
                    <form onSubmit={handleSubmit(handleSave)}>
                        <div className="space-y-3">
                            <Label className="block text-sm font-medium">Title</Label>
                            <Input
                                id="s:categoryConfig-title-input_field"
                                className={`mt-1 block w-full p-2 border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded`}
                                {...register("title",
                                    {
                                        required: 'Title is required',
                                    })}
                                placeholder="Enter title"
                            />
                            {errors.title && (
                                <p className="text-red-500 text-sm">{errors.title.message}</p>
                            )}

                            <Label className="block text-sm font-medium">Select Agents</Label>
                            <Controller
                                name="agents"
                                control={control}
                                render={({ field }) => (
                                    <ReactSelect
                                        {...field}
                                        options={agentOptions}
                                        value={agentOptions.filter(option => (field.value || []).includes(option.value))}
                                        onChange={(selected: any) => {
                                            const selectedIds = selected.map((option: any) => option.value);
                                            setValue('agents', selectedIds);
                                        }}
                                        isMulti
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                borderColor: 'none',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    borderColor: 'black',
                                                },
                                            }),
                                        }}
                                    />
                                )}
                            />

                            <div className="flex flex-row w-full justify-between items-center">
                                <Label className="block text-sm font-medium">Folders</Label>

                                <Button size="sm" onClick={(e) => { addFolder(); e.preventDefault() }} className="space-x-2 mt-2" id="s:categoryConfig-form-newFolder_button">
                                    <Plus className="size-5" /> <p className="hidden md:flex">New Folder</p>
                                </Button>
                            </div>
                            <div className="grid grid-cols-4 gap-3">
                                {folders?.map((folder, index) => (
                                    <div key={index}>
                                        <Card key={index} >
                                            <CardHeader>
                                                <div className="flex flex-row w-full justify-between items-center">
                                                    <Controller
                                                        name={`categoryFolders.${index}.integration`}
                                                        control={control}
                                                        rules={{
                                                            required: "Please select an integration",
                                                            validate: (value) => value !== '' || "Please select a valid integration"
                                                        }}
                                                        render={({ field, fieldState: { error } }) => (
                                                            <>
                                                                <Select
                                                                    value={folder?.integration ?? ''}
                                                                    onValueChange={(item: string) => setValue(`categoryFolders.${index}.integration`, item, {
                                                                        shouldValidate: true,
                                                                    })}
                                                                >
                                                                    <SelectTrigger className="w-full sm:max-w-[500px] md:max-w-[180px] mt-1 rounded-2xl">
                                                                        <SelectValue>
                                                                            {integrationList.find(ele => ele.id.toString() === folder.integration.toString())?.name || "Select an Integration"}
                                                                        </SelectValue>
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        {integrationList && integrationList.length > 0 ? (
                                                                            integrationList.map((item, indext) => (
                                                                                <SelectItem key={indext} value={item.id.toString()}>{item.name}</SelectItem>
                                                                            ))
                                                                        ) : (
                                                                            <li>No integration available</li>
                                                                        )}
                                                                    </SelectContent>
                                                                </Select>
                                                                {error && <p className="text-red-500 text-sm">{error.message}</p>}
                                                            </>
                                                        )} />
                                                    {folders?.length > 1 && (
                                                        <Controller
                                                            name={`categoryFolders.${index}.title`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Button
                                                                    id="s:categoryConfig-form-newFolder-delete_button"
                                                                    size="icon"
                                                                    variant="outline"
                                                                    onClick={(e) => { removeFolder(index); e.preventDefault(); }}
                                                                    className="border-red-500 p-2 rounded-full"
                                                                >
                                                                    <Trash className="size-5" />
                                                                </Button>
                                                            )} />
                                                    )}
                                                </div>
                                            </CardHeader>
                                            <CardContent>
                                                <Controller
                                                    name={`categoryFolders.${index}.title`}
                                                    control={control}
                                                    rules={{
                                                        required: "Folder title is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <Input
                                                            id="s:categoryConfig-folderTitle-input_field"
                                                            className={`mt-1 block w-full p-1 border ${errors.categoryFolders?.[index]?.title ? 'border-red-500' : 'border-gray-300'} rounded`}
                                                            {...field}
                                                            placeholder="Enter title" />
                                                    )} />
                                                {errors.categoryFolders?.[index]?.title && (
                                                    <p className="absolute mt-11 text-red-500 text-sm">
                                                        {errors.categoryFolders?.[index]?.title?.message}
                                                    </p>
                                                )}
                                                <Controller
                                                    name={`categoryFolders.${index}.description`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Input
                                                            id="s:categoryConfig-folderDescription-input_field"
                                                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                                            {...field}
                                                            value={folder.description ?? ''}
                                                            placeholder="Enter Description" />
                                                    )} />
                                                <Controller
                                                    name={`categoryFolders.${index}.folder`}
                                                    control={control}
                                                    rules={{
                                                        required: "Folder title is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <Input
                                                            id="s:categoryConfig-folderName-input_field"
                                                            {...field}
                                                            value={folder.folder ?? ''}
                                                            className={`mt-1 block w-full p-2 border  ${errors.categoryFolders?.[index]?.title ? 'border-red-500' : 'border-gray-300'} rounded`}
                                                            placeholder="Folder name" />
                                                    )} />
                                                {errors.categoryFolders?.[index]?.title && (
                                                    <p className="absolute mt-11 text-red-500 text-sm">
                                                        {errors.categoryFolders?.[index]?.title?.message}
                                                    </p>
                                                )}

                                                <div className="flex flex-row w-full justify-between">
                                                    <Controller
                                                        name={`categoryFolders.${index}.isDefault`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Label className="flex flex-col items-center space-y-1 mt-1">
                                                                <Switch
                                                                    id={`categoryFolders.${index}.isDefault`}
                                                                    checked={folder.isDefault}
                                                                    onClick={() => setValue(`categoryFolders.${index}.isDefault`, !folder.isDefault)} />
                                                                <span className="text-xs">Default</span>
                                                            </Label>
                                                        )} />

                                                    <Controller
                                                        name={`categoryFolders.${index}.isReference`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Label className="flex flex-col items-center space-y-1 mt-1 pl-1">
                                                                <Switch
                                                                    id={`categoryFolders.${index}.isReference`}
                                                                    checked={folder.isReference}
                                                                    onClick={() => setValue(`categoryFolders.${index}.isReference`, !folder.isReference)} />
                                                                <span className="text-xs">Attachment</span>
                                                            </Label>
                                                        )} />
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <div className="flex flex-row gap-2">
                                    <Switch
                                        id="collate"
                                        {...register("isCollate")}
                                        checked={isCollate}
                                        onCheckedChange={(val) => setValue('isCollate', val)}
                                    />
                                    <label htmlFor="collate" className="block text-sm font-medium">Collate</label>
                                </div>

                                {isCollate && (
                                    <Textarea
                                        id="prompt"
                                        className="mt-4 block w-full p-2 border border-gray-300 rounded min-h-[100px] h-auto"
                                        {...register("prompt")}
                                        placeholder="Enter Your Prompt"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="flex justify-end mt-4 space-x-2">
                            <Button size='lg' variant='outline' onClick={(e) => { setIsDialogOpen(false); e.preventDefault() }}>Cancel</Button>
                            <Button size='lg' type='submit' id="s:categoryConfig-form-update/save_button" disabled={!isValid}>
                                {isEditing ? 'Update' : 'Save'}
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}