import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from ".";


export const creatAgent = createAsyncThunk(
    'api/agentConfig',
    async (payload: any, { rejectWithValue }) => {
        const api = axiosInstance
        try {
            const { data } = await api.post(`/agents`, payload);
            return data;
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const deleteAgent = createAsyncThunk(
    'api/agentConfig/deleteAgent',
    async (id: number, { rejectWithValue }) => {
        const api = axiosInstance;
        try {
            const { data } = await api.delete(`/agents/${id}`);
            return data;
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateAgent = createAsyncThunk(
    'api/agentConfig/updateAgent',
    async (value: any, { rejectWithValue }) => {
        if (!value.id) {
            return rejectWithValue("Agent ID is missing!");
        }
        const api = axiosInstance
        try {
            const { data } = await api.put(`/agents/${value.id}`, value);
            return data;
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const getAgentList = createAsyncThunk(
    'api/agentConfig/agentList',
    async (_, { rejectWithValue }) => {
        const api = axiosInstance
        try {
            const { data } = await api.get('/agents');
            return data;
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)