import { PencilIcon, Plus, Trash } from "lucide-react"
import { Button } from "../../../core/components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../core/components/ui/card"
import { DataTable } from "../../../shared/components/data-table/data-table"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog"
import { Label } from "../../../core/components/ui/label"
import { Input } from "../../../core/components/ui/input"
import { Textarea } from "../../../core/components/ui/textarea"
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify';
import { memo, useEffect, useRef, useState } from "react"
import { ColumnDef } from "@tanstack/react-table"
import { CaretSortIcon } from "@radix-ui/react-icons"
import { AppDispatch, RootState } from "../../../redux-store/stores/store"
import { useDispatch, useSelector } from "react-redux"
import { creatAgent, deleteAgent, getAgentList, updateAgent } from "../../../shared/services/agentConfig"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../../core/components/ui/alert-dialog"
import { agentModel } from "../../../shared/models/agentConfig-model"
import { fetchCompanyList } from "../../../shared/services/manager"

export const AgentConfiguration = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const { agent } = useSelector((state: RootState) => state.agentConfig);
    const getAgentCalled = useRef(false);

    const fetchCompany = fetchCompanyList();
    const companyData = fetchCompany.values().next().value;
    const companyId = companyData?.companyId;

    useEffect(() => {
        if (!getAgentCalled.current) {
            dispatch(getAgentList())
            getAgentCalled.current = true;
        }
    }, [dispatch])

    const { register, setValue, reset, control, watch, handleSubmit, getValues, formState: { errors, isValid } } = useForm({
        defaultValues: {
            id: '',
            title: '',
            mainPrompt: '',
            conditionalPrompt: '',
            inputPrompt: '',
            outputPrompt: ''
        }
    });

    const handleSave = async (value: any) => {
        try {
            if (isEditing) {
                const agentId = getValues("id");  // Ensure id is obtained
                if (!agentId) throw new Error("Agent ID is missing!");

                await dispatch(updateAgent({ ...value, id: agentId })).unwrap();
                toast.success('Agent updated successfully');
            } else {
                await dispatch(creatAgent(value)).unwrap();
                toast.success("Agent added successfully");
            }
        } catch (error) {
            if (isEditing) {
                toast.error('Error updating Agent: ' + error);
            } else {
                toast.error('Error adding Agent: ' + error);
            }
        } finally {
            setIsDialogOpen(false);
        }
    };

    const handleDelete = (agentId: number) => {
        try {
            dispatch(deleteAgent(agentId)).unwrap();
            toast.success("Agent Deleted successfully");
        } catch (error) {
            toast.error('Error Deleting Agent: ' + error);
        };
    }

    const openEditDialog = (agent: any, agentConfig: any) => {
        setValue('id', agentConfig.id)
        setValue('title', agentConfig.title);
        setValue('mainPrompt', agent.mainPrompt);
        setValue('conditionalPrompt', agent.conditionalPrompt);
        setValue('inputPrompt', agent.inputPrompt);
        setValue('outputPrompt', agent.outputPrompt);
        setIsEditing(true);
        setIsDialogOpen(true);
    };


    const getColumns = (
        handleDelete: (value: any) => void,
        openEditDialog: (value: any, config: any) => void
    ): ColumnDef<agentModel, any>[] => [
            {
                accessorKey: "id",
                header: ({ column }) => (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        ID
                        <CaretSortIcon className="ml-2 h-4 w-4" />
                    </Button>
                ),
                cell: memo(({ row }) => (
                    <div className="flex ml-10">{row.getValue("id")}</div>
                )),
            },
            {
                accessorKey: "title",
                header: ({ column }) => (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Title
                        <CaretSortIcon className="ml-2 h-4 w-4" />
                    </Button>
                ),
                cell: memo(({ row }) => (
                    <div className="flex ml-10">{row.getValue("title")}</div>
                )),
            },
            {
                id: 'actions',
                cell: ({ row }: any) => {
                    const agentz = row.original.promptConfig;
                    const agentConfig = row.original;
                    return (
                        <div className="flex flex-row items-center justify-end space-x-2 mr-6">
                            <Button variant="outline" size="icon" onClick={() => openEditDialog(agentz, agentConfig)} className="border-blue-500 rounded-full" id="s:agentConfig-table-edit_button">
                                <PencilIcon className="size-5" color='black' />
                            </Button>
                            {agentConfig.company.toString() === companyId?.toString() && (
                                <ConfirmDeleteButton onConfirm={() => handleDelete(agentConfig.id)} />
                            )}
                        </div>
                    );
                },
            },
        ];

    const handleDialog = () => {
        resetForm();
        setIsEditing(false);
        setIsDialogOpen(true);
    };

    const resetForm = () => {
        reset({
            title: '',
            mainPrompt: '',
            conditionalPrompt: '',
            inputPrompt: '',
            outputPrompt: ''
        })
    };

    return (
        <div className="flex flex-row w-full overflow-auto gap-6 p-6  h-100vh">
            <Card className="w-full h-fit">
                <CardHeader>
                    <CardTitle className="flex w-full flex-row items-start md:items-center justify-between">
                        <p className="text-xl font-bold mb-2">Agent Management </p>
                        <Button size={"lg"} onClick={handleDialog} className="space-x-2" id="s:setting-agenetConfig_api_button">
                            <Plus className="size-5" /> <p className="hidden md:flex">Create New Agent</p>
                        </Button>
                    </CardTitle>
                    <CardDescription>
                        <span className="flex flex-row w-full items-center justify-between">
                            <span className="font-bold">
                                Manage Agent List
                            </span>
                        </span>
                    </CardDescription>
                </CardHeader>
                <CardContent className="flex flex-col justify-center items-center">
                    <DataTable data={agent} getColumns={() => getColumns(handleDelete, openEditDialog)} />
                </CardContent>
            </Card>

            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogContent className="w-full sm:max-w-[1300px] p-4 sm:p-6 rounded-md bg-white max-h-[80vh] overflow-y-auto">
                    <DialogHeader>
                        <DialogTitle>{isEditing ? 'Edit Agent' : 'Add New Agent'}</DialogTitle>
                        <DialogDescription></DialogDescription>
                    </DialogHeader>
                    <form onSubmit={handleSubmit(handleSave)}>
                        <div className="space-y-3">
                            <Label className="block text-sm font-bold">Title</Label>
                            <Input
                                id="s:agentConfig-title-input_field"
                                className={`mt-1 block w-full p-2 border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded`}
                                {...register("title",
                                    {
                                        required: 'Title is required',
                                    })}
                                placeholder="Enter title"
                            />
                            {errors.title && (
                                <p className="text-red-500 text-sm">{errors.title.message}</p>
                            )}
                            <div className="flex grid grid-cols-2 gap-4">
                                <div>
                                    <Label className="font-semibold">Main Prompt</Label>
                                    <Textarea
                                        id="prompt"
                                        className="mt-2 block w-full p-2 border border-gray-300 rounded min-h-[70px] h-auto"
                                        {...register("mainPrompt")}
                                        placeholder="Enter Your Main Prompt"
                                    />
                                </div>
                                <div>
                                    <Label className="font-semibold">Conditional Prompt</Label>
                                    <Textarea
                                        id="prompt"
                                        className="mt-2 block w-full p-2 border border-gray-300 rounded min-h-[70px] h-auto"
                                        {...register("conditionalPrompt")}
                                        placeholder="Enter Your Conditional Prompt"
                                    />
                                </div>
                                <div>
                                    <Label className="font-semibold">Input Prompt</Label>
                                    <Textarea
                                        id="prompt"
                                        className="mt-2 block w-full p-2 border border-gray-300 rounded min-h-[70px] h-auto"
                                        {...register("inputPrompt")}
                                        placeholder="Enter Your Input Prompt"
                                    />
                                </div>
                                <div>
                                    <Label className="font-semibold">Output Prompt</Label>
                                    <Textarea
                                        id="prompt"
                                        className="mt-2 block w-full p-2 border border-gray-300 rounded min-h-[70px] h-auto"
                                        {...register("outputPrompt")}
                                        placeholder="Enter Your Output Prompt"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end mt-4 space-x-2">
                            <Button size='lg' variant='outline' onClick={(e) => { setIsDialogOpen(false); e.preventDefault() }}>Cancel</Button>
                            <Button size='lg' type='submit' id="s:agentConfig-form-update/save_button" disabled={!isValid}>
                                {isEditing ? 'Update' : 'Save'}
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div >
    )
};
const ConfirmDeleteButton = ({ onConfirm }: { onConfirm: () => void }) => {
    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button size="icon" variant="outline" className="border-secondary rounded-full" id="s:agentConfig-table-delete_button">
                    <Trash className="size-5" />
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Delete Agent</AlertDialogTitle>
                    <AlertDialogDescription>Are you sure you want to delete this Agent?</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>
                        <Button variant="ghost" id="s:agentConfig-table-delete-cancel_button">Cancel</Button>
                    </AlertDialogCancel>
                    <AlertDialogAction>
                        <Button variant="default" onClick={onConfirm} id="s:agentConfig-table-delete-confirm_button">Delete</Button>
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default AgentConfiguration;