import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../core/components/ui/button";
import { Input } from "../../../core/components/ui/input";
import { Label } from "../../../core/components/ui/label";
import { Textarea } from "../../../core/components/ui/textarea";
import { Controller, useForm } from "react-hook-form";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { getCategoryConfig, saveCategoryConfig } from "../../../shared/services/categoryConfig";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../core/components/ui/select";
import { Switch } from "../../../core/components/ui/switch";
import { Plus, Trash } from "lucide-react";
import { toast } from 'react-toastify';
import { useEffect, useRef } from "react";
import { Categories } from "../../../shared/models/categoryConfig-model";
import { Card, CardContent, CardHeader } from "../../../core/components/ui/card";
import { agents } from "../../../shared/models/agentConfig-model";
import ReactSelect from "react-select";
import { getAgentList } from "../../../shared/services/agentConfig";

export const ProjectConfig = () => {
    const { integrationList } = useSelector((state: RootState) => state.userSetting);
    const { category } = useSelector((state: RootState) => state.chat)
    const { agent } = useSelector((state: RootState) => state.agentConfig);
    const getCatogeryCalled = useRef(false);
    const { register, setValue, reset, control, watch, handleSubmit, formState: { errors, isValid } } = useForm({
        defaultValues: {
            title: '',
            categoryFolders: [{ title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }],
            prompt: '',
            isCollate: false,
            agents: [] as any
        } as unknown as Categories
    });


    const dispatch: AppDispatch = useDispatch();

      useEffect(() => {
        if (!getCatogeryCalled.current) {
          dispatch(getAgentList())
          dispatch(getCategoryConfig())
          getCatogeryCalled.current = true;
        }
      }, [dispatch])

    const isCollate = watch('isCollate', false);
    const folders = watch('categoryFolders', [{ title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }]);

    const handleSave = (data: any) => {

        const agentIds = data.agents;
        data.categoryFolders = data.categoryFolders.map((item: any) => {
            item.paths = [];
            return item
        })

        const updatedCategory = { ...category, ...data, agents: agentIds };

        dispatch(saveCategoryConfig(updatedCategory))
            .then(() => {
                toast.success('Category updated successfully');
                dispatch(getCategoryConfig())
            })
            .catch((error) => {
                toast.error('Error updating category', error);

                resetForm()
            });

    };

    const resetForm = () => {
        reset({
            title: '',
            categoryFolders: [{ title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }],
            prompt: '',
            isCollate: false,
            agents: []
        })
    };

    const addFolder = () => setValue('categoryFolders', [...folders ?? [], { title: '', description: '', folder: '', isReference: false, integration: '', paths: [], isDefault: false }]);


    const removeFolder = (index: number) => {
        if (folders.length > 1) {
            const updatedFolders = folders.filter((_, i) => i !== index);
            setValue('categoryFolders', updatedFolders);
        }
    };



    useEffect(() => {
        resetForm()
        if (category) {
            setTimeout(() => {
                setValue('title', category.title);
                setValue('categoryFolders', category.categoryFolders);
                setValue('prompt', category.prompt);
                setValue('isCollate', category.isCollate);
                setValue('agents', category.agents.map((ag: agents) => ag.id) as any []);
            }, 500)
        }

    }, [category])

    const agentOptions = agent?.map((ag: agents) => ({
        value: ag.id,
        label: ag.title
    })) || [];

    return (
        <>
            <form onSubmit={handleSubmit(handleSave)}>
                <div className="space-y-1 overflow-y-auto h-[67vh]">
                    <Label className="block text-sm font-medium">Title</Label>
                    <Input
                        id="s:categoryConfig-title-input_field"
                        className={`mt-1 block w-full p-2 border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded`}
                        {...register("title",
                            {
                                required: 'Title is required',
                            })}
                        placeholder="Enter title"
                    />
                    {errors.title && (
                        <p className="text-red-500 text-sm">{errors.title.message}</p>
                    )}
                    <Label className="block text-sm font-medium">Select Agents</Label>
                    <Controller
                        name="agents"
                        control={control}
                        render={({ field }) => (
                            <ReactSelect
                                {...field}
                                options={agentOptions}
                                value={agentOptions.filter(option => (field.value as unknown as number[]).includes(option.value))}                             
                                onChange={(selected: any) => {
                                    const selectedIds = selected.map((option: any) => option.value);
                                    setValue('agents', selectedIds);
                                }}
                                isMulti
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        borderColor: 'none',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            borderColor: 'black',
                                        },
                                    }),
                                }}
                            />
                        )}
                    />
                    <div className="flex flex-row w-full justify-between items-center">
                        <Label className="block text-sm font-medium">Folders</Label>

                        <Button size="sm" onClick={(e) => { addFolder(); e.preventDefault() }} className="space-x-2 mt-0" id="s:categoryConfig-form-newFolder_button">
                            <Plus className="size-4" /> <p className="hidden md:flex">New Folder</p>
                        </Button>
                    </div>
                    <div className="grid grid-cols-4 gap-2 ">

                        {folders?.map((folder, index) => (
                            <div  key={index}>
                                <Card key={index} >
                                    <CardHeader className="-mt-3">
                                        <div className="flex flex-row w-full justify-between items-center">
                                            <Controller
                                                name={`categoryFolders.${index}.integration`}
                                                control={control}
                                                rules={{
                                                    required: "Please select an integration",
                                                    validate: (value) => value !== '' || "Please select a valid integration"
                                                }}
                                                render={({ field, fieldState: { error } }) => (
                                                    <div className="flex flex-col">
                                                        <Label>Select Drive</Label>
                                                        <Select
                                                            value={folder?.integration ?? ''}
                                                            onValueChange={(item: string) => setValue(`categoryFolders.${index}.integration`, item, {
                                                                shouldValidate: true,
                                                            })}
                                                        >
                                                            <SelectTrigger className="w-full sm:max-w-[500px] md:max-w-[180px] rounded-2xl">
                                                                <SelectValue>
                                                                    {integrationList.find(ele => ele.id.toString() === folder.integration.toString())?.name || "Select an Integration"}
                                                                </SelectValue>
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                {integrationList && integrationList.length > 0 ? (
                                                                    integrationList.map((item, indext) => (
                                                                        <SelectItem key={indext} value={item.id.toString()}>{item.name}</SelectItem>
                                                                    ))
                                                                ) : (
                                                                    <li>No integration available</li>
                                                                )}
                                                            </SelectContent>
                                                        </Select>
                                                        {error && <p className="text-red-500 text-sm">{error.message}</p>}
                                                    </div>
                                                )} />
                                            {folders?.length > 1 && (
                                                <Controller
                                                    name={`categoryFolders.${index}.title`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Button
                                                            id="s:categoryConfig-form-newFolder-delete_button"
                                                            size="icon"
                                                            variant="outline"
                                                            onClick={(e) => { removeFolder(index); e.preventDefault(); }}
                                                            className="border-red-500 p-1 mt-3 rounded-full"
                                                        >
                                                            <Trash className="size-5" />
                                                        </Button>
                                                    )} />
                                            )}
                                        </div>
                                    </CardHeader>

                                    <CardContent className="space-y-0 -mt-5">
                                        <Controller
                                            name={`categoryFolders.${index}.title`}
                                            control={control}
                                            rules={{
                                                required: "Folder title is required",
                                            }}
                                            render={({ field }) => (
                                                <>
                                                    <Label>Title</Label>
                                                    <Input
                                                        id="s:categoryConfig-folderTitle-input_field"
                                                        className={`mt-1 block w-full p-1 border ${errors.categoryFolders?.[index]?.title ? 'border-red-500' : 'border-gray-300'} rounded`}
                                                        {...field}
                                                        placeholder="Enter title" />
                                                </>
                                            )} />
                                        {errors.categoryFolders?.[index]?.title && (
                                            <p className="absolute mt-11 text-red-500 text-sm">
                                                {errors.categoryFolders?.[index]?.title?.message}
                                            </p>
                                        )}


                                        <Controller
                                            name={`categoryFolders.${index}.description`}
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <Label>Description</Label>
                                                    <Input
                                                        id="s:categoryConfig-folderDescription-input_field"
                                                        className="mt-1 block w-full p-2 border border-gray-300 rounded"
                                                        {...field}
                                                        value={folder.description ?? ''}
                                                        placeholder="Enter Description" />
                                                </>
                                            )} />




                                        <Controller
                                            name={`categoryFolders.${index}.folder`}
                                            control={control}
                                            rules={{
                                                required: "Folder name is required",
                                            }}
                                            render={({ field }) => (
                                                <>
                                                    <Label>Folder Name</Label>
                                                    <Input
                                                        id="s:categoryConfig-folderName-input_field"
                                                        {...field}
                                                        value={folder.folder ?? ''}
                                                        className={`mt-1 block w-full p-2 border  ${errors.categoryFolders?.[index]?.title ? 'border-red-500' : 'border-gray-300'} rounded`}
                                                        placeholder="Folder name" />
                                                </>
                                            )} />
                                        {errors.categoryFolders?.[index]?.title && (
                                            <p className="absolute mt-11 text-red-500 text-sm">
                                                {errors.categoryFolders?.[index]?.title?.message}
                                            </p>
                                        )}

                                        <div className="flex flex-row w-full justify-between">
                                            <Controller
                                                name={`categoryFolders.${index}.isDefault`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Label className="flex flex-col items-center space-y-0 mt-1">
                                                        <Switch
                                                            id={`categoryFolders.${index}.isDefault`}
                                                            checked={folder.isDefault}
                                                            onClick={() => setValue(`categoryFolders.${index}.isDefault`, !folder.isDefault)} />
                                                        <span className="text-xs">Default</span>
                                                    </Label>
                                                )} />

                                            <Controller
                                                name={`categoryFolders.${index}.isReference`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Label className="flex flex-col items-center space-y-0 mt-1 pl-1">
                                                        <Switch
                                                            id={`categoryFolders.${index}.isReference`}
                                                            checked={folder.isReference}
                                                            onClick={() => setValue(`categoryFolders.${index}.isReference`, !folder.isReference)} />
                                                        <span className="text-xs">Attachment</span>
                                                    </Label>
                                                )} />
                                        </div>


                                    </CardContent>
                                </Card>
                            </div>
                        ))}
                    </div>
                    <div>
                        <div className="flex flex-row gap-2 mt-1">
                            <Switch
                                id="collate"
                                {...register("isCollate")}
                                checked={isCollate}
                                onCheckedChange={(val) => setValue('isCollate', val)}
                            />
                            <label htmlFor="collate" className="block text-sm font-medium">Collate</label>
                        </div>

                        {isCollate && (
                            <Textarea
                                id="prompt"
                                className="mt-1 block w-full p-2 border border-gray-300 rounded min-h-[100px] h-auto"
                                {...register("prompt")}
                                placeholder="Enter Your Prompt"
                            />
                        )}
                    </div>
                </div>

                <div className="flex justify-end mt-1 mb-1 space-x-2">
                    <Button size='lg' type='submit' id="s:categoryConfig-form-update/save_button" disabled={!isValid}>
                        Update
                    </Button>
                </div>
            </form>
        </>
    )
}