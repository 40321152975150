import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatService } from '../../shared/services/chat/chat-service'
import { getCategoryConfig } from "../../shared/services/categoryConfig";
import { Categories, categoryFolder } from "../../shared/models/categoryConfig-model";

export interface Chat {
    chatDetails: {
        name: string
        tokens: number
        isSelect: boolean
    }
    icon: string
    to: string
    id: number
    status: number
    type: number
    company: number
    createdUser: number,
    recivingResponse: boolean
    category: number
}

export interface References {
    fileName: string
    fileUrl: string
    thumbnailUrl: string
    contentType: string
    fileId: 4489
    pageNumbers: number[]
    folderPath: string
    filePath: string
    type: 0 | 1
    folderUrl: string | null
    integrationName: string | null
    chunkContent: string | null
}

export interface Message {
    id: number
    questionDetails: {
        text: string
        createdTime: string
        tokens: number | null
        isUser: boolean
        references: References[] | null
        primary: string[]
        reference: string[]
        isCollate: boolean
        sources: References[] | null
        categories: string[]
        collateFolders: categoryFolder[]
        includeFiles: boolean
    }
    answerDetails: {
        text: string | null
        createdTime: string | null
        tokens: number | null
        references: null
    }
    messageDetails: {
        text: string | null
        createdTime: string | null
        fileId: number
    }
    chatBox: number
    chatBoxId: number
    company: number
    status: number
    type: number
    updateInfo: any
    category: number
}

export interface IChatState {
    chatList: Chat[];   // Adjust the type based on your data structure
    messages: Message[];   // Adjust the type based on your data structure
    loading: boolean;
    error: string | null;
    selectedChatId: number | null;
    selectedToken: string | null;
    chatSelected: boolean
    newMessage: boolean;
    isShared: boolean;
    categories: Categories[],
    selectCategory: categoryFolder[],
    selectedCategoryfolder: categoryFolder[],
    chatModel: string | null;
    isCollate: boolean
    chatIncludeFiles: boolean,
    category: Categories | null
}

const initialState: IChatState = {
    chatList: [],
    messages: [],
    loading: false,
    error: null,
    selectedChatId: null,
    selectedToken: null,
    chatSelected: false,
    newMessage: false,
    selectCategory: [],
    isShared: false,
    chatModel: null,
    selectedCategoryfolder: [],
    isCollate: false,
    chatIncludeFiles: false,
    categories: [],
    category: null

};
const chatServiceInstance = new ChatService();

const chatSlice = createSlice({
    name: "chat",
    initialState: initialState,
    reducers: {
        selectChat: (state, { payload }) => {
            state.selectedChatId = parseInt(payload)
            state.chatSelected = true
        },
        selectToken: (state, { payload }) => {
            state.selectedToken = payload
            state.chatSelected = true
        },
        sharedCheck: (state, { payload }) => {
            state.isShared = payload
        },
        resetChatList: (state) => {
            state.chatList = []
        },
        resetSelectedChat: (state) => {
            state.selectedChatId = null
            state.selectedToken = null
            state.messages = []
            state.chatSelected = false
            state.selectCategory = []
        },
        setNewMessageStatus: (state, { payload }) => {
            state.newMessage = payload
        },
        updateMessage: (state, { payload }) => {
            if (state.messages && state.messages[state.messages.length - 1]) {
                state.messages[state.messages.length - 1].answerDetails.text = payload
            }
        },
        updateQuestion: (state, { payload }) => {
            const index = state.messages.length > 0 ? state.messages.length : 0
            state.messages[index] = {
                id: 0,
                questionDetails: {
                    text: payload.Question,
                    createdTime: '',
                    tokens: null,
                    isUser: true,
                    references: null,
                    sources: null,
                    categories: payload.Categories,
                    includeFiles: payload.IncludeFiles
                }
            } as Message
        },
        setChatModel: (state, { payload }) => {
            state.chatModel = payload;
        },
        setIsCollate: (state, { payload }) => {
            state.isCollate = payload;
        },
        setCategory: (state, { payload }) => {
            state.category = payload
            state.selectedCategoryfolder = payload.categoryFolders;
            if (payload.categoryFolders) {
                const referenceFolders = payload.categoryFolders.filter((folder: any) => folder.isReference);
                state.selectCategory = referenceFolders;
            }
        },

        setCategoryFolder: (state, { payload }) => {
            state.selectedCategoryfolder = state.selectedCategoryfolder.map((item) => {
                const updatedItem = (Array.isArray(payload) ? payload : [payload]).find((p: any) => p.title === item.title);
                return updatedItem ? { ...item, ...updatedItem } : item;
            });
        },

        setUpdateCategory: (state, { payload }) => {
            // Find the selected category object based on the title
            let selectCategoryObj = state.selectCategory.find(item => item.title === payload.title);
            if (selectCategoryObj) {
                if (payload.check) {
                    // If check is true, add filePath if it doesn't exist in paths
                    if (!selectCategoryObj.paths.includes(payload.filePath)) {
                        selectCategoryObj.paths.push(payload.filePath);
                    }
                } else {
                    // If check is false, remove filePath if it exists in paths
                    selectCategoryObj.paths = selectCategoryObj.paths.filter(item => item !== payload.filePath);
                }
                // Update state.selectCategory array by replacing the modified object
                state.selectCategory = state.selectCategory.map(item =>
                    item.title === payload.title && selectCategoryObj ? selectCategoryObj : item
                );
            }
        },

        setChatIncludeFiles: (state, { payload }) => {
            state.chatIncludeFiles = payload;
        },
    },
    extraReducers: (builder) => {
        // login user
        builder
            .addCase(chatServiceInstance.GetChatList.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.GetChatList.fulfilled, (state, action) => {
                state.chatList = action.payload;
                state.loading = false;
                state.chatList.forEach(item => {
                    item.to = `/home/c/${item.id}`
                })
                state.chatList = state.chatList.sort((a, b) => b.id - a.id)
            })
            .addCase(chatServiceInstance.GetChatList.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.GetChatListByCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.GetChatListByCategory.fulfilled, (state, action) => {
                state.chatList = action.payload;
                state.loading = false;
                state.chatList.forEach(item => {
                    item.to = `/home/p/${item.category}/c/${item.id}`
                })
                state.chatList = state.chatList.sort((a, b) => b.id - a.id)
            })
            .addCase(chatServiceInstance.GetChatListByCategory.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.GetMessages.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.GetMessages.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                action.payload.forEach((item: any) => {
                    item.questionDetails.isUser = true
                })
                const index = state.messages.length > 0 ? state.messages.length - 1 : 0
                state.messages.length > 0 ?
                    state.messages[index] = action.payload[index] :
                    state.messages = action.payload

                setNewMessageStatus(false)
            })
            .addCase(chatServiceInstance.GetMessages.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })


            .addCase(chatServiceInstance.GetTokenMessages.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.GetTokenMessages.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                action.payload.forEach((item: any) => {
                    item.questionDetails.isUser = true
                })
                const index = state.messages.length > 0 ? state.messages.length - 1 : 0
                state.messages.length > 0 ?
                    state.messages[index] = action.payload[index] :
                    state.messages = action.payload

                setNewMessageStatus(false)
            })
            .addCase(chatServiceInstance.GetTokenMessages.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.SendMessage.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.SendMessage.fulfilled, (state, action: PayloadAction<any>) => {
                action.payload.questionDetails.isUser = true
                const index = state.messages.length > 0 ? state.messages.length - 1 : 0
                state.messages[index] = action.payload
                state.loading = false;
            })
            .addCase(chatServiceInstance.SendMessage.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.DeleteMessage.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.DeleteMessage.fulfilled, (state, action: PayloadAction<any>) => {
                state.messages = state.messages.filter(msg => msg.id !== action.payload.id); // Example: remove the message
                state.loading = false;
            })
            .addCase(chatServiceInstance.DeleteMessage.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.DeleteChat.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.DeleteChat.fulfilled, (state, action: PayloadAction<any>) => {
                state.chatList = state.chatList.filter(chat => chat.id !== action.payload.id); // Example: remove the chat
                state.loading = false;
            })
            .addCase(chatServiceInstance.DeleteChat.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })


            .addCase(getCategoryConfig.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCategoryConfig.fulfilled, (state, action: PayloadAction<any>) => { // Example: remove the chat
                state.loading = false;
                state.categories = action.payload.res
            })
            .addCase(getCategoryConfig.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })

            .addCase(chatServiceInstance.updateTitle.pending, (state) => {
                state.loading = true;
            })
            .addCase(chatServiceInstance.updateTitle.fulfilled, (state, action: PayloadAction<any>) => {
                const existingChatIndex = state.chatList.findIndex(chat => chat.id === action.payload.id);

                if (existingChatIndex !== -1) {
                    state.chatList[existingChatIndex].chatDetails.name = action.payload.chatDetails.name;

                    state.chatList[existingChatIndex] = {
                        ...state.chatList[existingChatIndex],
                        ...action.payload,
                    };
                } else {
                    state.chatList.push(action.payload);
                }

                state.loading = false;
            })
            .addCase(chatServiceInstance.updateTitle.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loading = false;
            })
    }
});

export const { selectChat, selectToken, resetSelectedChat, setNewMessageStatus, updateMessage, setCategory, setChatModel, sharedCheck, setCategoryFolder, setUpdateCategory, setChatIncludeFiles, updateQuestion, setIsCollate, resetChatList } = chatSlice.actions;
export default chatSlice.reducer;