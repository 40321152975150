import React, { memo, useEffect, useRef, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../../core/components/ui/card';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog";
import { Button } from '../../../core/components/ui/button';
import { DataTable } from '../../../shared/components/data-table/data-table';
import { Plus, Trash, PencilIcon } from 'lucide-react';
import { toast } from 'react-toastify';
import { Input } from '../../../core/components/ui/input';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '../../../core/components/ui/alert-dialog';
import { Label } from '../../../core/components/ui/label';
import { getCategoryConfig, saveCategoryConfig, deleteCategoryConfig } from '../../../shared/services/categoryConfig';
import { AppDispatch, RootState } from '../../../redux-store/stores/store';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnDef } from '@tanstack/react-table';
import { Categories } from '../../../shared/models/categoryConfig-model';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { getConnectedDrive } from '../../../shared/services/userSetting';
import { CategoryDialog } from '../../../shared/components/category-add-dialog/categoryAddDialog';
import { getAgentList } from '../../../shared/services/agentConfig';

const CategoryConfig = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<any>(null);
  const getCatogeryCalled = useRef(false);
  const dispatch: AppDispatch = useDispatch();
  const { categories } = useSelector((state: RootState) => state.categories);
  const { agent } = useSelector((state: RootState) => state.agentConfig);



  useEffect(() => {
    if (!getCatogeryCalled.current) {
      dispatch(getAgentList())
      dispatch(getCategoryConfig())
      dispatch(getConnectedDrive());
      getCatogeryCalled.current = true;
    }
  }, [dispatch])



  const openAddDialog = () => {
    setCurrentCategory(null);
    setIsEditing(false);
    setIsDialogOpen(true);
  };

  const openEditDialog = (category: any) => {
    setCurrentCategory(category);
    setIsEditing(true);
    setIsDialogOpen(true);
  };



  const handleDelete = (categoryId: number) => {
    dispatch(deleteCategoryConfig(categoryId))
      .then(() => {
        toast.success('Category deleted successfully');
      })
      .catch((error) => {
        toast.error('Error deleting category: ' + error);
      });
  };

  const getColumns = (
    handleDelete: (value: any) => void,
    openEditDialog: (value: any) => void
  ): ColumnDef<Categories, any>[] => [
      {
        accessorKey: "id",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            ID
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: memo(({ row }) => (
          <div className="flex ml-10">{row.getValue("id")}</div>
        )),
      },
      {
        accessorKey: "title",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Title
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: memo(({ row }) => (
          <div className="flex ml-10">{row.getValue("title")}</div>
        )),
      },
      {
        id: 'actions',
        cell: ({ row }: any) => {
          const Categories = row.original;
          return (
            <div className="flex flex-row items-center justify-end space-x-2 mr-6">
              <Button variant="outline" size="icon" onClick={() => openEditDialog(Categories)} className="border-blue-500 rounded-full" id="s:categoryConfig-table-edit_button">
                <PencilIcon className="size-5" color='black' />
              </Button>
              <ConfirmDeleteButton onConfirm={() => handleDelete(Categories.id)} />
            </div>
          );
        },
      },
    ];



  return (
    <div className="flex flex-row md:flex-row w-full overflow-auto gap-6 p-6 h-100vh">
      <Card className="w-full md:w-[calc(100%-1.5rem)] h-fit">
        <CardHeader>
          <CardTitle className="flex w-full flex-row items-start md:items-center justify-between">
            <p className="text-xl font-bold mb-2">Category Configuration</p>
            <Button size="lg" onClick={openAddDialog} className="space-x-2">
              <Plus className="size-5" /> 
              <p className="hidden md:flex" id="s:categoryConfig-addNewCategory_button">Add New Category</p>
            </Button>
          </CardTitle>
          <CardDescription className='font-bold'>Manage Categories</CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col justify-center items-center">
          <DataTable data={categories} getColumns={() => getColumns(handleDelete, openEditDialog)} />
        </CardContent>
      </Card>

      {/* Add/Edit Category Dialog */}
      <CategoryDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} currentCategory={currentCategory} isEditing={isEditing} agent={agent} />
    </div>
  );
};

const ConfirmDeleteButton = ({ onConfirm }: { onConfirm: () => void }) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button size="icon" variant="outline" className="border-secondary rounded-full" id="s:categoryConfig-table-delete_button">
          <Trash className="size-5" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Category</AlertDialogTitle>
          <AlertDialogDescription>Are you sure you want to delete this category?</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            <Button variant="ghost" id="s:categoryConfig-table-delete-cancel_button">Cancel</Button>
          </AlertDialogCancel>
          <AlertDialogAction>
            <Button variant="default" onClick={onConfirm} id="s:categoryConfig-table-delete-confirm_button">Delete</Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CategoryConfig;


