import React, { Component, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

class ErrorBoundary extends Component<Props> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
