import { createSlice } from "@reduxjs/toolkit";
import { agentModel } from "../../shared/models/agentConfig-model";
import { creatAgent, deleteAgent, getAgentList, updateAgent } from "../../shared/services/agentConfig";


const initialState: agentModel = {
    loading: false,
    error: '',
    agent: [],
    success: false,
    resetSuccess: false,
}

const integrationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = '';
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(creatAgent.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(creatAgent.fulfilled, (state, { payload }) => {
                if (payload) {
                    state.agent.push({
                        id: payload.id,
                        company: payload.company,
                        title: payload.title,
                        promptConfig: payload.promptConfig,
                        status: payload.status
                    });
                }
                state.loading = false;
                state.success = true;
            })
            .addCase(creatAgent.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(updateAgent.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(updateAgent.fulfilled, (state, { payload }) => {
                console.log(payload);
                const exists = state.agent.some(agen => agen.id === payload.id);
                if (!exists) {
                    state.agent.push(payload);
                } else {
                    state.agent = state.agent.map((agen) =>
                        agen.id === payload.id
                            ? {
                                ...agen,
                                id: payload.id,
                                title: payload.title,
                                promptConfig: payload.promptConfig,
                                status: payload.status,
                                company: payload.company,
                            }
                            : agen
                    );
                }
                state.loading = false;
                state.success = true;
            })
            .addCase(updateAgent.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(getAgentList.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getAgentList.fulfilled, (state, { payload }) => {
                state.agent = payload;
                state.loading = false;
                state.success = true;
            })
            .addCase(getAgentList.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(deleteAgent.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(deleteAgent.fulfilled, (state, { payload }) => {
                const id = typeof payload === 'object' ? payload.id : payload;
                const filterItem = state.agent.filter(item => item.id !== id);
                state.agent = filterItem;
                state.loading = false;
                state.success = true;
            })
            .addCase(deleteAgent.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

    }
})

export const { resetState,
    resetSuccess } = integrationSlice.actions
export default integrationSlice.reducer