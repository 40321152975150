import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '.';



export const getCategoryConfig = createAsyncThunk(
  'api/getCatogeryConfig',
  async (_, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get('/company/categories');
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const saveCategoryConfig = createAsyncThunk(
  'api/saveCategoryConfig',
  async (categories: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.post(`/company/category`, categories);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const deleteCategoryConfig = createAsyncThunk(
  'api/deleteCategoryConfig',
  async (id: number, { rejectWithValue }) => {
    const api = axiosInstance;
    try {
      const { data } = await api.delete(`/company/categories/${id}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);



