import { CogIcon, Folder } from "lucide-react"
import { NavLink } from "react-router-dom"

export const Projects = ({ editCategory, handleOnclick, title, id }: any) => {

    return (
        <>
            <NavLink to={`/home/p/${id}`} onClick={(e) => {
                handleOnclick()
            }} className={({ isActive }) => isActive ? "flex flex-row w-full hover:bg-muted-foreground/20 p-2 rounded-md bg-primary/10 font-semibold h-[36px]" : "flex flex-row w-full hover:bg-muted-foreground/20 p-2 rounded-md h-[36px]"}>
                <div className=" flex flex-row w-full space-x-2 items-center justify-between">
                    <div className="flex flex-row w-fit items-center space-x-2 text-sm">
                        <Folder className="size-5" />
                        <p>
                            {title}
                        </p>
                    </div>
                    {/* <CogIcon onClick={() => {
                        editCategory()
                    }} /> */}
                </div>
            </NavLink>
        </>
    )
}