import { isEmpty } from "lodash"
import { Link, Link2Icon, MenuSquare, Plus, SendHorizontal } from "lucide-react"
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "../../core/components/ui/button"
import { Sheet, SheetContent, SheetTrigger } from "../../core/components/ui/sheet"
import { resetSelectedChat, selectChat, setCategory, setCategoryFolder, setChatIncludeFiles, setChatModel, setIsCollate, setNewMessageStatus, sharedCheck, updateMessage, updateQuestion } from "../../redux-store/reducers/chat-reducers"
import { AppDispatch, RootState } from "../../redux-store/stores/store"
import { ChatService } from "../../shared/services/chat/chat-service"
import { getConfig } from "../../shared/services/config-handler"
import { ChatBubble } from "./components/chat-bubble"
import icon from '../../assets/icons/icon.png'
import CommandInput from "../../shared/components/command-input/command-input"
import { Label } from "../../core/components/ui/label"
import CategoryPop from "./components/categoryPath"
import { getCategoryConfig } from "../../shared/services/categoryConfig"
import SharedLinkpop from "./components/sharedLink"
import { Switch } from "../../core/components/ui/switch"
import { Navbar } from "./components/nav-bar"
import { getConnectedDrive } from "../../shared/services/userSetting"
import { ProjectConfig } from "./components/project-config"


export const ChatLayout = () => {


    const [open, setOpen] = useState(false);
    const [categorySelected, setCategorySelected] = useState(false);
    const [openCategory, setCategoryOpen] = useState(false);

    const [_prompt, setPrompt] = useState<string>('')
    const [isNewChat, setIsNewChat] = useState<boolean>(false)

    const dispatch = useDispatch<AppDispatch>()
    const isLoaded = useRef(false);
    const chatModel = useSelector((state: RootState) => state.chat.chatModel);
    const includeFiles = useSelector((state: RootState) => state.chat.chatIncludeFiles)


    const [recivingResponse, setRecivingResponse] = useState(false)

    const chatServiceInstatnce = useMemo(() => new ChatService(), []);

    const { chatList, selectedChatId, messages, chatSelected, newMessage, categories, isCollate, selectedCategoryfolder } = useSelector((state: RootState) => state.chat)
    const { userInfo } = useSelector((state: RootState) => state.auth)

    const navigate = useNavigate()
    const params = useParams()

    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);


    useEffect(() => {
        if (params && params['chat-id']) {
            dispatch(selectChat(params['chat-id']))
        }
        if (window.location.pathname.includes('shared')) {
            dispatch(sharedCheck(true))
        }

        if (params && params['cat-id']) {
            setCategorySelected(true)
            dispatch(chatServiceInstatnce.GetChatListByCategory(params['cat-id']));
            const selectedCategory = categories.find((item: { id: number }) => item.id === parseInt(params['cat-id'] as string));
            if (selectedCategory) {
                dispatch(setChatModel(selectedCategory.title));
                dispatch(setIsCollate(selectedCategory.isCollate))
                dispatch(setCategory(selectedCategory));
            }
        }

        if (params && !params['cat-id']) {
            dispatch(setChatModel(null))
            setCategorySelected(false)
        }

        if (isEmpty(params)) {
            dispatch(resetSelectedChat())
            dispatch(chatServiceInstatnce.GetChatList({}))
            dispatch(setChatModel(null))
            setCategorySelected(false)
        }

        return () => {
            dispatch(resetSelectedChat())
            setRecivingResponse(false)
        }
    }, [params])

    const handleDialog = () => {
        navigate('/profile/integrations')
    }

    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPrompt(event.target.value)
    }


    const handleSendMessage = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        sendMessage()
    }

    const handleCategoryChange = (selectedTitle: any) => {
        const selectedCategory = categories.find((item: { title: any }) => item.title === selectedTitle);
        if (selectedCategory) {
            dispatch(setChatModel(selectedTitle));
            dispatch(setIsCollate(selectedCategory.isCollate))
            dispatch(setCategory(selectedCategory));
        }
    };

    const handleAttachClick = () => {
        setCategoryOpen(!openCategory);
    }

    const sendMessage = () => {
        const message = {
            id: selectedChatId,
            Question: _prompt,
            Categories: chatModel ? [chatModel] : null,
            IncludeFiles: includeFiles,
            isCollate: isCollate,
            collateFolders: selectedCategoryfolder
        }
        if (!selectedChatId) {
            dispatch(setNewMessageStatus(true))
        }
        dispatch(updateQuestion(message))

        dispatch(chatServiceInstatnce.SendMessage(message))
        setPrompt('')
    }

    const handlesharedLink = () => {
        setOpen(!open);
    }

    useEffect(() => {
        if (selectedChatId) {
            dispatch(chatServiceInstatnce.GetMessages({ id: selectedChatId }))
            setIsNewChat(false)
            const config = getConfig()
            setRecivingResponse(false)

            const unsubscribe = chatServiceInstatnce.newMessage(parseInt(userInfo?.userId as string) as number, selectedChatId as number, "QnA", (resp: any) => {
                if (resp.length > 0) {
                    const message = resp[0]
                    switch (message?.ProgressBarStatus) {
                        case 1:
                            if (message.ProgressValue === 20) {
                                dispatch(chatServiceInstatnce.GetMessages({ id: message.ChatboxId }))
                            }
                            if (message.ProgressValue > 0) {
                                dispatch(updateMessage(message.Message))
                            }
                            setRecivingResponse(true)
                            dispatch(setNewMessageStatus(false))
                            break;
                        case 2:
                            const path = `${config?.REACT_APP_ENV}/User/${userInfo?.userId}/Notifications/${message.id}`
                            message.IsViewed = true
                            chatServiceInstatnce.updateViewedMessage(path, message)
                            dispatch(chatServiceInstatnce.GetMessages({ id: message.ChatboxId }))
                            setRecivingResponse(false)
                            break;
                        default:
                            setRecivingResponse(false)
                            break;
                    }
                }
            })

            return () => unsubscribe()
        }// eslint-disable-next-line
    }, [selectedChatId])


    useEffect(() => {
        if (!isLoaded.current) {
            dispatch(chatServiceInstatnce.GetChatList({}))
            dispatch(getCategoryConfig())
            isLoaded.current = true
        }// eslint-disable-next-line
    }, [chatServiceInstatnce, dispatch])

    useEffect(() => {
        if (messages && messages.length === 1 && chatList && newMessage) {
            if (!chatList.find(item => item.id === messages[0].chatBox) && messages[0].chatBox) {
                dispatch(chatServiceInstatnce.GetChatList({}))
                navigate(messages[0].category ? `/home/p/${messages[0].category}/c/${messages[0].chatBox}` : `/home/c/${messages[0].chatBox}`)
            }

        }// eslint-disable-next-line

    }, [messages])

    const isUserNearBottom = () => {
        const chatContainer = chatContainerRef.current;
        if (!chatContainer) return false;

        const threshold = 250; // Allow 150px from the bottom
        const scrollPosition = chatContainer.scrollTop + chatContainer.clientHeight;
        const scrollHeight = chatContainer.scrollHeight;

        return scrollHeight - scrollPosition <= threshold;
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (isEmpty(params)) {
            dispatch(resetSelectedChat())
            dispatch(chatServiceInstatnce.GetChatList({}))
        }
        dispatch(getConnectedDrive());
        scrollToBottom();
    }, []);


    useEffect(() => {
        if (isUserNearBottom()) {
            scrollToBottom();
        }

        if (messages && messages.length > 0 && messages[messages.length - 1]?.answerDetails?.references) {
            scrollToBottom()
        }
    
        if (selectedChatId && messages && messages.length > 0) {
            const lastMessage = messages[messages.length - 1];
    
            if (Array.isArray(lastMessage?.answerDetails?.references)) {
                const references = lastMessage.answerDetails.references as any[];
                if (references.length > 0) {
                    scrollToBottom();
                }
            }            
    
            if (Array.isArray(lastMessage?.questionDetails?.categories) && lastMessage.questionDetails.categories.length > 0) {
                const category = lastMessage.questionDetails.categories[0];
                dispatch(setChatModel(category));
                handleCategoryChange(category);
            }
    
            if (lastMessage?.questionDetails?.collateFolders !== null) {
                dispatch(setIsCollate(lastMessage.questionDetails.isCollate));
                dispatch(setCategory({ categoryFolders: lastMessage.questionDetails.collateFolders }));
            }
    
            if (lastMessage?.questionDetails?.includeFiles) {
                dispatch(setChatIncludeFiles(lastMessage.questionDetails.includeFiles));
            }
        } else {
            dispatch(setChatIncludeFiles(false));
            dispatch(setCategoryFolder({ selectedCategoryfolder: null }));
            dispatch(setIsCollate(false));
        }
    }, [messages, selectedChatId]);
    



    return (
        <div className="flex flex-row w-full h-full transition-colors z-0">
            <div className="hidden md:flex ">
                <Navbar />
            </div>
            <div className="flex flex-col w-full px-4 md:px-10 py-2 bg-white h-full">
                <div className="flex flex-col w-full h-full rounded-xl  mx-auto relative justify-between">
                    <div className="md:hidden absolute z-10 flex flex-row w-full items-center justify-between">
                        <div className="flex flex-row w-full h-full bg-white space-x-2">
                            <Sheet>
                                <SheetTrigger className="rounded-full bg-primary text-primary-foreground p-2">
                                    <MenuSquare className="size-5" />
                                </SheetTrigger>
                                <SheetContent side={"left"} >
                                    <div className="flex flex-col w-full h-full">
                                        <Navbar />
                                    </div>
                                </SheetContent>
                            </Sheet>
                        </div>
                        <div className="flex flex-row w-full h-fit bg-white space-x-2">
                            {selectedChatId && (
                                <div className="flex items-end">
                                    <Button className="flex items-center" onClick={handlesharedLink}>
                                        <Link2Icon className="size-3" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="hidden md:flex top-0 flex-row w-full h-fit bg-white space-x-2">
                        {selectedChatId && (
                            <div className="absolute right-0 items-end justify-end mt-1">
                                <Button className="space-x-2" onClick={handlesharedLink}>
                                    <Link2Icon className="size-5" />
                                    <p>Shared Link</p>
                                </Button>
                            </div>
                        )}
                        {openCategory && <CategoryPop open={openCategory} setOpen={setCategoryOpen} />}
                        {open && <SharedLinkpop open={open} setOpen={setOpen} />}
                    </div>

                    {!isNewChat && !chatSelected && !newMessage && categorySelected &&
                        <div className="flex flex-col w-full  max-w-7xl mx-auto">
                            <ProjectConfig />
                        </div>
                    }

                    {!isNewChat && !chatSelected && !newMessage && !categorySelected &&
                        <div className="flex flex-col h-full justify-between items-center w-full">
                            <div className="flex flex-row w-fit h-full justify-center items-center">
                                <div className="flex flex-col h-fit w-fit p-8  rounded-2xl space-y-8">
                                    <div className="flex flex-col items-center justify-center w-full h-full space-y-6">
                                        <img src={icon} className="h-[8rem]" alt="icon" />
                                        <div className="flex flex-col max-w-96 items-center text-center justify-center space-y-6">
                                            <h1 className="text-primary text-3xl" >How can I help you today?</h1>
                                            <p className="text-muted-foreground text-xl text-center">
                                                You can use any prompt to query through your cloud storages. To begin you can integrate your cloud storages.</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center justify-center w-full h-full space-x-5">
                                        <Button size={"lg"} className="space-x-2" onClick={() => handleDialog()} id="s:dashboard-chat-layout-integration-button">
                                            <Plus className="size-5" /> <p>Integration</p>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {(isNewChat || newMessage || chatSelected) &&
                        <div
                            ref={chatContainerRef} className="flex flex-col max-w-7xl w-full mx-auto h-[78vh] overflow-y-auto pt-10 md:pt-0 md:px-4 mt-12 mb-2 flex-initial " >
                            {
                                messages.map((item, index) => {
                                    return <div key={index} className="flex flex-col w-full h-fit space-y-4 py-4">
                                        <ChatBubble key={`message-${item.id}-q`} {...(item.questionDetails)} />
                                        <ChatBubble key={`message-${item.id}-a`} {...item.answerDetails} id="sss" />
                                    </div>
                                })
                            }
                            {chatSelected && recivingResponse &&
                                <div className="flex flex-row space-x-2 pl-18">
                                    <div className="w-3 h-3 rounded-full bg-black animate-typing delay-0"></div>
                                    <div className="w-3 h-3 rounded-full bg-black animate-typing delay-75"></div>
                                    <div className="w-3 h-3 rounded-full bg-black animate-typing delay-150"></div>
                                </div>}
                            <div ref={messagesEndRef} />
                        </div>
                    }
                    <div
                        className="mb-4 flex flex-col w-full  max-w-7xl mx-auto bg-muted rounded-3xl items-center p-2 border focus-within:ring-1 focus-within:ring-ring"
                    >
                        <div className="flex flex-row w-full pb-2">
                            <CommandInput

                                value={_prompt}
                                handleKeyUp={(value) => handleKeyUp(value)}
                                handleOnChange={(value) => handleOnChange(value)}
                                setPrompt={(value) => setPrompt(value)}
                                recivingResponse={recivingResponse}
                                placeholder="Type your prompt here..."
                                isDropdownAbove={true}

                            />
                        </div>
                        <div className="flex flex-row w-full justify-between px-2">
                            <div className="flex flex-row w-full  max-w-7xl mx-auto items-center space-x-2">
                                <Button size="icon" className="rounded-full" onClick={handleAttachClick}>
                                    <Link className="size-5" />
                                </Button>
                                <Switch id="terms"
                                    checked={includeFiles}
                                    onCheckedChange={(checked) => dispatch(setChatIncludeFiles(checked ? true : false))} />
                                <Label
                                    htmlFor="terms"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Include All Relative Files
                                </Label>
                            </div>


                            <Button size="icon" className="rounded-full" onClick={(event) => handleSendMessage(event)} disabled={recivingResponse} id="s:dashboard-chat-send_button">
                                <SendHorizontal className="size-5" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}