import { MessageSquare, Plus } from "lucide-react"
import { Button } from "../../../core/components/ui/button"
import { ScrollArea } from "../../../core/components/ui/scroll-area"
import { Separator } from "../../../core/components/ui/separator"
import { CategoryDialog } from "../../../shared/components/category-add-dialog/categoryAddDialog"
import { useMemo, useState } from "react"
import { Titles } from "./titles"
import { Projects } from "./projects"
import { useDispatch, useSelector } from "react-redux"
import { Chat, resetSelectedChat, setCategory, setChatModel, setIsCollate } from "../../../redux-store/reducers/chat-reducers"
import { AppDispatch, RootState } from "../../../redux-store/stores/store"
import { useNavigate } from "react-router-dom"

import { ChatService } from "../../../shared/services/chat/chat-service"
import { getAgentList } from "../../../shared/services/agentConfig"

export const Navbar = () => {


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const { agent } = useSelector((state: RootState) => state.agentConfig);

    const [currentCategory, setCurrentCategory] = useState<any>(null);

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()


    const { chatList, categories, } = useSelector((state: RootState) => state.chat)

    const handleNewChat = () => {
        dispatch(resetSelectedChat())
        dispatch(getAgentList())
        // setIsNewChat(true)
        navigate('/home')
    }
    const openAddDialog = () => {
        setCurrentCategory(null);
        setIsEditing(false);
        setIsDialogOpen(true);
    };


    const openEditDialog = (item: any) => {
        setCurrentCategory(item)
        setIsEditing(true);
        setIsDialogOpen(true);
    };


    const handleCategoryChange = (selectedTitle: any) => {
        dispatch(setChatModel(selectedTitle.title));
        dispatch(setIsCollate(selectedTitle.isCollate))
        dispatch(setCategory(selectedTitle));
    };
    return (
        <>
            <aside className="flex-shrink-0 flex flex-col w-full  md:w-[260px] py-6 px-2 h-full space-y-4 justify-between relative">
                <div className="h-full w-full space-y-4">
                    <div className="flex flex-col max-h-[30vh]" >
                        <div className="pb-2 text-sm font-bold space-y-2">
                            <div className="flex flex-row w-full justify-between">
                                <div className="text-2xl" id="s:dashboard-chat-projects-projectsTitle">Projects</div>
                                <Button size="sm" onClick={openAddDialog} className="rounded-full">
                                    <Plus className="size-5" />
                                </Button>
                            </div>
                            <Separator />

                            <CategoryDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} currentCategory={currentCategory} isEditing={isEditing} agent={agent}/>
                        </div>
                        <div className="flex flex-col h-full">
                            <ScrollArea>
                                <div className="flex flex-col max-h-[28vh] overflow-auto">
                                    {
                                        categories?.map((item: any) => (
                                            <Projects key={`category-${item.id}`} {...item} handleOnclick={() => { handleCategoryChange(item) }} editCategory={() => {
                                                openEditDialog(item)
                                            }} />
                                        ))
                                    }
                                </div>
                            </ScrollArea>
                        </div>
                    </div>
                    <div className="flex flex-col w-full h-auto max-h-[58vh] md:space-y-2">
                        <div className="pb-1 text-sm font-bold space-y-2 pt-2">
                            <div className="text-2xl pt-2" id="s:dashboard-chat-chatHistory-chathistoryTitle"> Chat History</div>
                            <Separator />
                        </div>
                        <div className="flex flex-col h-auto">
                            <ScrollArea>
                                <div className="flex flex-col h-full max-h-[43vh] overflow-auto">
                                    {
                                        chatList.map((item: Chat) => (
                                            <Titles key={`chat-${item.id}`} {...item} />
                                        ))
                                    }
                                </div>
                            </ScrollArea>
                        </div>
                    </div>
                </div>
                <div className="w-full  md:w-[240px] absolute bottom-4">
                    <Button size={"lg"} onClick={handleNewChat} className="w-full space-x-2 bg-foreground" id="s:dashboard-chat-chatHistory-newChat_button"><MessageSquare className="size-5" /> <span>Start New Chat</span></Button>
                </div>
            </aside>
        </>
    )
}

